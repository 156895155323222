import React, { useState, useRef, useEffect } from "react"
import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import toast from 'react-hot-toast'
import { navigate } from 'gatsby-link'
import { useLocation } from '@reach/router'
import SimpleReactValidator from "simple-react-validator"
import API from "../../api/postings"
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import Thankyou from '../../components/addon/thankYou'
import useScript from "../../service/useScript"
import APIButton from "../../components/addon/button/apiButton"
import { acceptOnlyNumbers } from "../../service/helper"
import UseCustomValidator from "../../service/simpleValidator"


const Postings = () => {
    const location = useLocation()
    const metaTags = []
    const [update, forceUpdate] = useState(false)
    const [thankYou, setThankYou] = useState(false)
    const [detailpageData, setDetailpageData] = useState(null)
    const { customMobileValidator } = UseCustomValidator()
    const formValidator = customMobileValidator
    const [departmentList] = useState([
        {
            id: "Hospital Website",
            label: "Hospital Website"
        },
        {
            id: "Newspaper",
            label: "Newspaper"
        },
        {
            id: "Naukri.com",
            label: "Naukri.com"
        },
        {
            id: "Facebook",
            label: "Facebook"
        },
        {
            id: "Linkedin",
            label: "Linkedin"
        },
        {
            id: "Twitter",
            label: "Twitter"
        },
        {
            id: "Word of Mouth",
            label: "Word of Mouth"
        },
        {
            id: "Other",
            label: "Other"
        },
    ])

    const defaultState = {
        name: "",
        age: "",
        email_id: "",
        phone_no: "",
        mobile_no: "",
        category: detailpageData?.detailType || '',
        upload_your_resume: "",
        how_did_u_knw_abt_vac: "Hospital Website",
        designation : detailpageData?.designation || ''
    }

    let [formData, setFormData] = useState(defaultState)
    const [captcha, setCaptcha] = useState("");
    const [captchaError, setcaptchaError] = useState(false)
    const [resumeError, setResumeError] = useState('')
    const [resumePdf, setResumePdf] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false);

    console.log('=============props ',detailpageData)

    useEffect(() => {
        if (typeof window.initSelect !== "undefined") window.initSelect()
    }, [departmentList, thankYou])



    const onFormDataChange = (e) => {
        const { id, value } = e.target
        if(id === 'phone_no' || id === 'mobile_no' || id === 'age') {
            let val = acceptOnlyNumbers(value)
            setFormData({
                ...formData,
                [id]: val
            })
        } else {
            setFormData({
                ...formData,
                [id]: value
            })
        }
    }

    const handleResume = async (e) => {
        let file = e.target.files[0]
        if (file) {
            let fileType = file?.type;
            if (fileType !== "application/pdf") {
                setResumeError("Please upload your resume in .PDF format")
            } else {
                setResumeError("");
                setResumePdf(file)
            }
        }
    }

    const onDepartmentChange = (event) => {
        setFormData({ ...formData, 	how_did_u_knw_abt_vac: event.target.value })
    }

    const designationChange = (event) => {
        setFormData({ ...formData, designation: event.target.value })
    }
    
    const categoryChange = (event) => {
        setFormData({ ...formData, category: event.target.value })
    }
    
    const onSubmit = async () => {
        if(formData.designation === '') {formData.designation = detailpageData?.designation}
        if(formData.category === '') {formData.category = detailpageData?.detailType}
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            if (!resumePdf) {
                setResumeError("Please upload your resume in .PDF format")
            }
            forceUpdate(true)
        } else {
            if (validateCaptcha(captcha) === true) {
                if (!resumePdf) {
                    setResumeError("Please upload your resume in .PDF format")
                } else {
                    setSubmitDisabled(true);
                    const res = await API.submitFile(resumePdf)
                    .catch(err => {
                        console.log(err)
                        toast.error("Something went wrong")
                    })
                    
                    if(res && res.fid && res.fid.length > 0){
                        let webformId = ''
                        if(detailpageData?.detailType && (detailpageData?.detailType).toLowerCase() === ('Medical').toLowerCase()) { 
                            webformId = 'medical_careers_form'
                        }else if(detailpageData?.detailType && (detailpageData?.detailType).toLowerCase() === ("Non medical").toLowerCase()){
                            webformId = 'non_medical_careers_form'
                        }else if(detailpageData?.detailType && (detailpageData?.detailType).toLowerCase() === ('Nurses').toLowerCase()){
                            webformId = 'nurses_careers_form'
                        }else{
                            webformId = 'careers'
                        }

                        let id = res.fid[0].value.toString();
                        const response = await API.submitFormData({ ...formData, upload_your_resume: id, webform_id: webformId }).catch(err => {
                            console.log(err)
                            toast.error("Something went wrong")
                        })
                        if (response) {
                            setCaptcha("")
                            setThankYou(true);
                            resetForm();
                            window.scroll({ top: 0, behavior: "smooth" })
                        }
                    }else{
                        setSubmitDisabled(false);
                    }
                }
            }
            else {
                setcaptchaError(true)
                setCaptcha("");
                forceUpdate(!update)
            }
        }
    }

    const resetForm = () => {
        setFormData(defaultState)
        formValidator.current.hideMessages()
        forceUpdate(!update)
    }

    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }

    useEffect(() => {
        if (thankYou === false) {
            loadCaptchaEnginge(6, "black", "white");
        }

    }, [thankYou])

    useEffect(() => {
        if(location && window && location?.state) {
            if(location.state?.detailPage) {
                setDetailpageData(location?.state?.detailPage[0])
            } else {
                setDetailpageData(null)
            }
        }
    },[location])

    const handleCaptcha = (e) => {
        setcaptchaError(false);
        setCaptcha(e.target.value);
    }

    useScript("/assets/js/custom_select.js")

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: ["/assets/js/form-inputs-anim.js"],
                        css: [
                            "/assets/css/login.css",
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={metaTags}
                />
                <main className="innerpage">
                    {
                        thankYou ? <Thankyou setThankYou={resetThankyou} /> : (
                            <section className="section_bg login_section pt-first-section">
                                <div className="container">
                                    { detailpageData && <div className="mb-5 detailContent">
                                        <h2 class="pb-2">{detailpageData?.detailType}</h2>
                                        <p style={{color:"#F68C42"}}>{detailpageData?.designation} :</p>
                                        <div dangerouslySetInnerHTML={{ __html: detailpageData.data || '' }}></div>
                                    </div>
                                    }
                                    <h2 className="section-heading">Apply Now</h2>
                                    <div className="row no-gutters">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="white_rounded_box">
                                                <div className="form_wrap">
                                                    <div className="form_block pl-0">
                                                        {/* <p>Please specify your query below and we will revert back to you with the solution within no time.</p> */}
                                                        <div className="row">
                                                            {detailpageData && <div class="col-lg-12">
                                                                <div class="form-group select-dd">
                                                                    <label>Designation <span class="mandatory">*</span></label>
                                                                    <select name="" onChange={designationChange} placeholder={detailpageData?.designation ? detailpageData?.designation : "Select Designation"} class="form-control custom-select">
                                                                        {detailpageData?.designationList.map( (item, i) => {
                                                                            return(
                                                                                <option value={item.field_title} key={i}>{item.field_title}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>}
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Name <span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.name}
                                                                        id="name"
                                                                        name="name"
                                                                    />
                                                                    {formValidator.current.message('name', formData.name, 'required|alpha_space')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Email ID<span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.email_id}
                                                                        id="email_id"
                                                                        name="email_id"
                                                                    />
                                                                    {formValidator.current.message('email_id', formData.email_id, 'required|email')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Phone No.</label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.phone_no}
                                                                        id="phone_no"
                                                                        name="phone_no"
                                                                    />
                                                                    {formData.phone_number && formValidator.current.message('phone_no', formData.phone_no, 'min:10|max:10|validateMobile')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Mobile No. <span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="tel"
                                                                        value={formData.mobile_no}
                                                                        id="mobile_no"
                                                                        name="mobile_no"
                                                                    />
                                                                    {formValidator.current.message('mobile_no', formData.mobile_no, 'required|min:10|max:10|validateMobile')}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <div className="form-group -animated">
                                                                    <label>Age <span className="mandatory">*</span></label>
                                                                    <input className="form-control" type="text" onChange={onFormDataChange} name="age" id="age" value={formData.age} />
                                                                    {formValidator.current.message('Age', formData.age, 'required|min:1|max:2')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group select-dd">
                                                                    <label>How did you hear about the opening?</label>
                                                                    <select onChange={onDepartmentChange} name="" id=""
                                                                        className="form-control custom-select" placeholder="Select Option">
                                                                        {
                                                                            departmentList.map((el, index) => {
                                                                                return (
                                                                                    <option value={el.id} key={index}>{el.label}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group select-dd">
                                                                    <label>Category <span className="mandatory">*</span></label>
                                                                    <select onChange={categoryChange} value={detailpageData?.detailType} name="" id="" className="form-control custom-select" placeholder={detailpageData?.detailType ? detailpageData?.detailType : "Select Category"}>
                                                                        <option value="Doctors" key="0">Doctors</option>
                                                                        <option value="Nurses" key="1">Nurses</option>
                                                                        <option value="Non Medical" key="2">Non Medical</option>
                                                                    </select>
                                                                    {formValidator.current.message('Category', formData?.category, 'required')}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group-file-upload">
                                                                    <label htmlFor="resume"> Upload your resume in .PDF format<span className="mandatory">*</span></label>
                                                                    <input name="resume" className="form-control" type="file" onChange={handleResume}/>
                                                                    {resumeError.length > 0 ? <p className="field_error">{resumeError}</p> : ""}
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-6">
                                                                <div className="mb-0">
                                                                    <LoadCanvasTemplate />
                                                                </div>
                                                                <div className="form-group -animated">
                                                                    <label>Type characters shown in the picture <span className="mandatory">*</span></label>
                                                                    <input className="form-control" type="text" value={captcha} onChange={handleCaptcha} id="captcha" />
                                                                    {formValidator.current.message('captcha', captcha, 'required')}
                                                                    {captchaError ? <p className="field_error">Invalid captcha value</p> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <APIButton 
                                                                title={`Submit`}
                                                                loading={submitDisabled}
                                                                disabled={submitDisabled}
                                                                onBtnClick={() => onSubmit()}  
                                                                className={`btn btn-primary`} 
                                                            />
                                                            {/* <button onClick={onSubmit} disabled={submitDisabled} className="btn btn-primary">Continue</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    }

                </main>
            </Layout>
        </>
    )
}
export default Postings